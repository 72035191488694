"use client";

import { useEffect, ReactNode } from "react";
import TagManager from "react-gtm-module";

interface GTMInitializerProps {
    children: ReactNode;
    gtmId: string;
}

export default function GTMInitializer({ children, gtmId }: GTMInitializerProps) {
    useEffect(() => {
        const handleLoad = () => {
            TagManager.initialize({ gtmId });
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [gtmId]);

    return <>{children}</>;
}
